import { SelectionChangedEvent } from "ag-grid-community";
import { TransactionTypeMappingEntity } from "domain/entity/TransactionTypeMapping/TransactionTypeMappingEntity";
import { INITIAL_TRANSACTION_TYPE_MAPPING_COL_DEF } from "presentation/constant/TransactionTypeMapping/TransactionTypeMappingMaintenanceColumnDefinition";
import { TransactionTypeMappingMaintenanceConstant } from "presentation/constant/TransactionTypeMapping/TransactionTypeMappingMaintenanceConstant";
import { useTransactionTypeMappingMaintenanceVM } from "presentation/hook/TransactionTypeMapping/useTransactionTypeMappingMaintenanceVM";
import { useTransactionTypeMappingMaintenanceTracked } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const TransactionTypeMappingTablePanel: React.FC = () => {
    const [transactionTypeMappingState] = useTransactionTypeMappingMaintenanceTracked();
    const transactionTypeMappingVM = useTransactionTypeMappingMaintenanceVM();
    const gridRef: any = useRef(null);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        transactionTypeMappingVM.updateSelectedRows(selectedRows);
    }, [transactionTypeMappingVM])

    const handleAddClick = useCallback(() => {
        transactionTypeMappingVM.onAdd();
    }, [transactionTypeMappingVM])

    const handleRowDoubleClick = useCallback((entity: TransactionTypeMappingEntity) => {
        transactionTypeMappingVM.onEdit(entity);
    }, [transactionTypeMappingVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='transaction-type-mapping-table'
                headerLabel={TransactionTypeMappingMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_TRANSACTION_TYPE_MAPPING_COL_DEF}
                data={transactionTypeMappingState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: TransactionTypeMappingEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [transactionTypeMappingState.tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(TransactionTypeMappingTablePanel);
